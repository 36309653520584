.text-red {
  color: $RED_COLOR;
}

.greys-anatomy {
  background-image: url("https://i.gifer.com/7lwg.gif");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position-x: right;
  background-position-y: -10px;
}
.game-of-oreo {
  background-image: url("https://i.gifer.com/71jz.gif");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position-x: right;
  background-position-y: -100px;
}
.golaco {
  background-image: url("https://i.gifer.com/2l4n.gif");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
}
.unicornio {
  background-image: url("https://i.gifer.com/ckl.gif");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.stranger-fini {
  background-image: url("https://i.gifer.com/7EJM.gif");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.sonho-de-vinkings {
  background-image: url("https://i.gifer.com/N8i8.gif");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position-x: right;
  background-position-y: center;
}
.divertidamente {
  background-image: url("https://i.gifer.com/EAoe.gif");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.bis-a-bis {
  background-image: url("https://i.gifer.com/6x5.gif");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.gradient {
  background-image: linear-gradient(
    90deg,
    rgba(75, 75, 75, 0) 0%,
    rgba(0, 0, 0, 01) 100%
  );
}
.gradient-player {
  background-image: linear-gradient(
    180deg,
    rgba(75, 75, 75, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

.txt-black {
  color: black;
}
.txt-white {
  color: white;
}
