@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  user-select: none;
}
body {
  color: #ffffff;
  background-color: #000000;
}

.border {
  border: 1px solid red;
}
