::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  border: 2px solid black;
  background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: #555;
}
